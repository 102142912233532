@import "../../include";

.header {
  display: flex;

  @media #{$tablet-and-desktop} {
    height: 100px;
  }

  @media #{$phone} {
    justify-content: center;
    height: 68px;
  }
}

.leftContainer {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: 250px;
  height: 78px;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$phone} {
    display: none;
  }
}

.menuItem {
  cursor: pointer;

  position: relative;

  display: flex;
  flex-shrink: 0; // Prevent new lines in menu
  align-items: center;
  justify-content: center;

  margin: 0 7px;
  padding: 4px 8px;

  font-size: 17px;
  font-weight: 500;
  line-height: 17px;
  color: #36162e;
  text-decoration: none;

  border: 0 none;

  transition: 125ms linear color;


  &:hover {
    color: #ff6d3f;
  }
}

.rightSpacer {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;

  @media #{$phone} {
    display: none;
  }
}
